import { render, staticRenderFns } from "./descriptionText.vue?vue&type=template&id=14d5c734&scoped=true"
import script from "./descriptionText.vue?vue&type=script&lang=js"
export * from "./descriptionText.vue?vue&type=script&lang=js"
import style0 from "./descriptionText.vue?vue&type=style&index=0&id=14d5c734&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14d5c734",
  null
  
)

export default component.exports